<template>
  <CCard>
    <CCardHeader>
      <h2>Category: {{ category }}</h2>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol col="9" />
        <CCol class="text-right">
          <CInput label="Search" horizontal @input="updateFilter" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CDataTable :items="items" :fields="fields" striped>
            <template #download="{ item }">
              <td>
                <a @click="downloadFile(item)">
                  <MIcon name="fa-download" />
                </a>
              </td>
            </template>
            <template #key="{ item }">
              <td>
                <a @click="downloadFile(item)">{{ item.key }}</a>
              </td>
            </template>
            <template #title="{ item }">
              <td>
                <a @click="downloadFile(item)">{{ item.title }}</a>
              </td>
            </template>
            <template #filename="{ item }">
              <td>
                <a @click="downloadFile(item)">{{ item.filename }}</a>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
<script>
const ITEMS = [
  {
    key: 'DBA-STAT-UPL',
    title: 'Latest Upload Log',
    filename: 'dba_DigestLog_2020-02-27_165639.xlsx',
    updatedDate: 'Feb 4, 2020 8:56 AM',
  },
  {
    key: 'DBA-WKB-FUNC',
    title: 'DBA Functional Info Workbook',
    filename: 'dba_func_2020-02-27_165645.xlsx',
    updatedDate: 'Feb 4, 2020 8:56 AM',
  },
  {
    key: 'DBA-WKB-TECH',
    title: 'DBA Technical Info Workbook',
    filename: 'dba_tech_2020-02-27_165654.xlsx',
    updatedDate: 'Feb 4, 2020 8:56 AM',
  },
  {
    key: 'CHVCAN_IDTSbx_cliDataDiagnostics',
    title: 'CHVCAN Client Data Diagnostics',
    filename: 'CHVCAN_IDTSbx_cliDataDiag_2020-02-19_202205.xlsx',
    updatedDate: 'Feb 3, 2020 12:22 PM',
  },
  {
    key: 'DBA-STAT-DEL',
    title: 'Latest Delete Request Logs',
    filename: 'dba_DelRequests_2020-02-26_005656.xlsx',
    updatedDate: 'Feb 2, 2020 4:56 PM',
  },
];

const FIELDS = [
  {
    key: 'download',
    label: '',
  },
  {
    key: 'key',
    label: 'Key',
  },
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'filename',
    label: 'File Name',
  },
  {
    key: 'updatedDate',
    label: 'Updated Date',
  },
];

export default {
  name: 'FileList',
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentFilter: null,
    };
  },
  computed: {
    items() {
      if (!this.currentFilter) return ITEMS;

      let filter = this.currentFilter;
      return ITEMS.filter(
        (item) =>
          item.key.search(filter) >= 0 ||
          item.filename.search(filter) >= 0 ||
          item.title.search(filter) >= 0
      );
    },
    fields() {
      return FIELDS;
    },
  },
  methods: {
    getLink(item) {
      return `files/category/${this.category}/${item.key}/${item.filename}`;
    },
    updateFilter(val) {
      this.currentFilter = val;
    },
    downloadFile(fileKey) {
      //console.log("downloading " + JSON.stringify(fileKey));
      this.$store
        .dispatch('tasks/downloadFile', { file })
        .then((res) => {
          this.errors = null;
          this.submitted = false;
          let data = res.data;
          let headers = res['headers'];
          let blob = new Blob([data], { type: headers['content-type'] });
          let downloadUrl = window.URL.createObjectURL(blob);
          window.open(downloadUrl, '_self', '', false);
        })
        .catch((error) => {
          this.submitted = true;
          this.errors = error.response.data.fields;
          this.$swal.fire({
            title: 'Error',
            text: 'The file does not exist or could not be downloaded.  Please correct the issues and try again.',
            icon: 'error',
          });
        })
        .finally(() => {
          //this.isSaving = false;
          //this.saveEnabled = true;
        });
    },
  },
};
</script>
