var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',[_c('h2',[_vm._v("Category: "+_vm._s(_vm.category))])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"col":"9"}}),_c('CCol',{staticClass:"text-right"},[_c('CInput',{attrs:{"label":"Search","horizontal":""},on:{"input":_vm.updateFilter}})],1)],1),_c('CRow',[_c('CCol',[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"striped":""},scopedSlots:_vm._u([{key:"download",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('MIcon',{attrs:{"name":"fa-download"}})],1)])]}},{key:"key",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{on:{"click":function($event){return _vm.downloadFile(item)}}},[_vm._v(_vm._s(item.key))])])]}},{key:"title",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{on:{"click":function($event){return _vm.downloadFile(item)}}},[_vm._v(_vm._s(item.title))])])]}},{key:"filename",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{on:{"click":function($event){return _vm.downloadFile(item)}}},[_vm._v(_vm._s(item.filename))])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }